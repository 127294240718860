@charset "UTF-8";

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #eb4d4b;
  --orange: #fd7e14;
  --yellow: #ee5a24;
  --green: #6ab04c;
  --teal: #00cec9;
  --cyan: #1289a7;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #1b1b1b;
  --primary: #098616;
  --secondary: #6c757d;
  --success: #6ab04c;
  --info: #1289a7;
  --warning: #ee5a24;
  --danger: #eb4d4b;
  --light: #f8f9fa;
  --dark: #1b1b1b;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Red Hat Display", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --text: #7cd959;
  --text-dark: hsla(120, 90%, 60%, 1);
  --text-darker: hsla(120, 90%, 60%, 0.8);
}

*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: "Red Hat Display", sans-serif !important;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  margin: 0;
  font-family: "Red Hat Display", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.8;
  color: rgb(255, 254, 254);
  text-align: left;
  background-color: #171d21;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  border-radius: 50px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 50px;
  background-color: #171d21;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6dc74c;
  border-radius: 50px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 700;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

a {
  background-color: transparent;
}

a:hover {
  color: #7cd959;
  text-decoration: none;
}

.container-fluid {
  max-width: 1440px;
  margin: auto;
  padding: 0 15px;
}

.site_header {
  padding: 25px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99999;
}

.body-no-scroll {
  overflow: hidden;
  height: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.modal-content::-webkit-scrollbar {
  display: none;
}

.modal-content {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.site_header.scrolled {
  background-color: #161d23;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.35);
}

.site_header .header_wrapper {
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 100;
}

.site_header .site_logo img {
  max-width: 280px;
}

.site_header .header_wrapper div {
  display: flex;
  align-items: center;
  margin-left: auto;

  a {
    color: #fff;
    font-size: 14px;
    text-decoration: none;
    margin-left: 10px;
  }
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  top: 92px;
}

.linked_pages,
.linked_pages_twitch {
  display: flex;
  flex-direction: column;
  position: relative;
  display: none;
}

.logo img {
  height: 70px;
  width: 120px;
}

pre {
  font-family: "Courier New", monospace;
  white-space: pre;
  border: 1px solid #ccc;
  padding: 10px;
  background-color: #000;
  border-radius: 5px;
}

.highlighted {
  color: #7cd959;
}

footer {
  background: #161d23;
  padding: 30px;
}

footer .footer_wrappper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

footer .footer_wrappper div {
  display: flex;
  align-items: center;
}

footer p {
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
  opacity: 0.8;
  margin-bottom: 0;
}

footer a {
  margin-left: 24px;
  color: #fff !important;
  text-decoration: none;
  font-weight: 200;
  font-size: 13px;
  opacity: 0.9;
  letter-spacing: 1px;
}

footer a:hover {
  color: #7cd959 !important;
}

/* .image-link {
  display: block;
} */

/* .btn-img {
  height: 73px;
  width: 275px;
  border-radius: 18px;
  box-shadow: 22px 12px 25px rgba(0, 0, 0, 0.8);
  transition: transform 0.3s ease;
}

.btn-img:hover {
  transform: translateY(-5px);
  box-shadow: 15px 10px 20px rgba(0, 0, 0, 0.25);
} */

/* Desktop  */
header,
header .mobile-menu,
.paragraph,
.logo img {
  display: none;
}

@media screen and (min-width: 1024px) {
  .container {
    width: 100%;
  }

  .streamers {
    margin: 0 10px;
    background: #7cd959 !important;
    border: 2px solid white;
    border-style: solid none none solid;
    color: #ffff !important;
    border-radius: 8px !important;
    box-shadow: 2px 2px white;
    cursor: pointer;
    padding: 0.563rem 1.125rem;
  }

  .streamers a {
    text-decoration: none;
    -webkit-text-stroke: 0.3px #000;
    color: #ffff !important;
  }

  .streamers:hover {
    transform: translateY(-2px);
    color: #000 !important;
  }

  .streamer_body {
    margin-right: 64px;
    margin-left: 64px;
    margin-bottom: 36px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: stretch;
    gap: 10px;
  }

  .table_contents {
    width: clamp(200px, 18%, 360px);
    margin-right: 48px;
    position: sticky;
    top: 100px;
  }

  .table_contents a {
    text-decoration: none;
    color: #fff;
  }

  .table-of-contents__item {
    margin: 20px 0;
  }

  .setup_list li {
    list-style-type: lower-alpha;
    margin: 16px 0;
    cursor: pointer;
  }

  .table_contents a:hover,
  .setup_list li:hover {
    color: #7cd959 !important;
  }

  .table_body {
    flex: 1;
    border-radius: 16px;
    padding: 48px;
    box-shadow: 8px 5px 15px #161d23;
  }

  .table_html p {
    font-weight: bold;
  }

  .table_body p {
    color: white;
    font-weight: bold;
  }

  .table_html a {
    color: #7cd959 !important;
    text-decoration: none;
  }

  .faq-title {
    list-style-type: none;
  }

  .question {
    margin: 20px 0;
  }

  .collapsible {
    background-color: #283642;
    color: #fff;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #283642;
    border-radius: 12px;
  }

  .active,
  .collapsible:hover {
    background-color: #283642;
  }

  .collapsible::after {
    /* content: "\25be"; */
    content: "\2B9F";
    color: #7cd959;
    font-weight: 700;
    margin-left: 5px;
  }

  .active::after {
    /* content: "\25B4"; */
    content: "\2B9D";
  }

  .content {
    padding: 18px;
    overflow: hidden;
    background-color: #283642;
    color: #fff;
    font-weight: bold;
    border: 1px solid #283642;
    border-radius: 12px;
  }

  .responsive-video {
    text-align: center;
  }

  .responsive-video video {
    all: initial;
    display: block;
    margin: auto;
    max-width: 100%;
    height: auto;
  }
}

/* mobile view  */
@media only screen and (min-device-width: 320px) and (max-device-width: 1024px) {
  footer {
    display: flex;
    flex-wrap: wrap !important;
    padding: 5%;
  }

  footer a {
    font-weight: 200;

    font-size: 10px;
  }

  footer p {
    font-size: 10.5px;
    margin-bottom: 2%;
  }

  .copyright {
    position: relative;
  }

  .streamer_body {
    padding: 16px;
    margin: 0 10px;
    box-shadow: 8px 5px 15px #161d23;
  }

  .table_contents {
    display: none;
  }

  .table_html a {
    color: #7cd959 !important;
    text-decoration: none;
  }

  h1,
  h2,
  h3,
  h4,
  p,
  ul,
  li {
    font-size: 14px;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  .question button {
    width: 100%;
    text-align: left;
  }

  .collapsible:after {
    content: "\002B";
    color: #7cd959;
    font-weight: 700;
    float: right;
    margin-left: 5px;
  }

  .active:after {
    content: "\2212";
  }

  .content {
    display: none;
    padding: 10px;
  }

  .collapsible.active + .content {
    display: block;
  }

  /* Adjust dock image size for smaller screens */
  .dock-image {
    height: auto;
    max-width: 100%;
  }

  .responsive-video video {
    width: 100%;
  }

  .responsive-video-container iframe {
    width: 100%;
    height: auto;
  }

  .flex-container {
    flex-direction: column;
  }

  .flex-item {
    width: 100% !important;
  }

  .giveaway-wrapper {
    display: none;
  }
}

/* Streamer's Body*/
.streamer_header {
  padding: 48px;
  text-align: center;
}

/* Top button */
#myBtn {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: transparent;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 8px;
}

#myBtn:hover {
  background-color: transparent;
}

.flex-container {
  display: flex;
  justify-content: space-between;
}

.flex-item {
  width: 48%;
  border: 2px solid white;
  /* Adjust the thickness and color as needed */
  border-radius: 15px;
  /* Adjust the radius for the desired roundness */
  padding: 15px;
  /* Add some padding to keep content away from the edges */
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  /* Optional: add a subtle shadow for depth */
}

.code-container {
  background-color: #f4f4f4;
  padding: 1rem;
  border-radius: 5px;
  border: 1px solid #ddd;
  overflow: auto;
}

.language-json {
  font-family: "Courier New", monospace;
  font-size: 14px;
  line-height: 1.4;
  color: #ffff;
  white-space: pre-wrap;
}

/**
 * Coldark Theme for Prism.js
 * Theme variation: Cold
 * Tested with HTML, CSS, JS, JSON, PHP, YAML, Bash script
 * @author Armand Philippot <contact@armandphilippot.com>
 * @homepage https://github.com/ArmandPhilippot/coldark-prism
 * @license MIT
 */
code[class*="language-"],
pre[class*="language-"] {
  color: #22667f;
  background: none;
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}

pre[class*="language-"]::-moz-selection,
pre[class*="language-"] ::-moz-selection,
code[class*="language-"]::-moz-selection,
code[class*="language-"] ::-moz-selection {
  background: #8da1b9;
}

pre[class*="language-"]::selection,
pre[class*="language-"] ::selection,
code[class*="language-"]::selection,
code[class*="language-"] ::selection {
  background: #8da1b9;
}

/* Code blocks */
pre[class*="language-"] {
  padding: 1em;
  margin: 0.5em 0;
  overflow: auto;
}

:not(pre) > code[class*="language-"],
pre[class*="language-"] {
  background: #283642;
}

/* Inline code */
:not(pre) > code[class*="language-"] {
  padding: 0.1em 0.3em;
  border-radius: 0.3em;
  white-space: normal;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: #63326f;
}

.token.punctuation {
  color: #ffff;
}

.token.delimiter.important,
.token.selector .parent,
.token.tag,
.token.tag .token.punctuation {
  color: #52006d;
}

.token.attr-name,
.token.boolean,
.token.boolean.important,
.token.number,
.token.constant,
.token.selector .token.attribute {
  color: #755f00;
}

.token.class-name,
.token.key,
.token.parameter,
.token.property,
.token.property-access,
.token.variable {
  color: #58008e;
}

.token.attr-value,
.token.inserted,
.token.color,
.token.selector .token.value,
.token.string,
.token.string .token.url-link {
  color: #116b00;
}

.token.builtin,
.token.keyword-array,
.token.package,
.token.regex {
  color: #af00af;
}

.token.function,
.token.selector .token.class,
.token.selector .token.id {
  color: #7c00aa;
}

.token.atrule .token.rule,
.token.combinator,
.token.keyword,
.token.operator,
.token.pseudo-class,
.token.pseudo-element,
.token.selector,
.token.unit {
  color: #a04900;
}

.token.deleted,
.token.important {
  color: #c22f2e;
}

.token.keyword-this,
.token.this {
  color: #005a8e;
}

.token.important,
.token.keyword-this,
.token.this,
.token.bold {
  font-weight: bold;
}

.token.delimiter.important {
  font-weight: inherit;
}

.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}

.language-markdown .token.title,
.language-markdown .token.title .token.punctuation {
  color: #005a8e;
  font-weight: bold;
}

.language-markdown .token.blockquote.punctuation {
  color: #af00af;
}

.language-markdown .token.code {
  color: #006d6d;
}

.language-markdown .token.hr.punctuation {
  color: #005a8e;
}

.language-markdown .token.url > .token.content {
  color: #116b00;
}

.language-markdown .token.url-link {
  color: #755f00;
}

.language-markdown .token.list.punctuation {
  color: #af00af;
}

.language-markdown .token.table-header {
  color: #111b27;
}

.language-scss .token.variable {
  color: #006d6d;
}

/* overrides color-values for the Show Invisibles plugin
 * https://prismjs.com/plugins/show-invisibles/
 */
.token.token.tab:not(:empty):before,
.token.token.cr:before,
.token.token.lf:before,
.token.token.space:before {
  color: #3c526d;
}

/* overrides color-values for the Toolbar plugin
 * https://prismjs.com/plugins/toolbar/
 */
div.code-toolbar > .toolbar.toolbar > .toolbar-item > a,
div.code-toolbar > .toolbar.toolbar > .toolbar-item > button {
  color: #e3eaf2;
  background: #005a8e;
}

div.code-toolbar > .toolbar.toolbar > .toolbar-item > a:hover,
div.code-toolbar > .toolbar.toolbar > .toolbar-item > a:focus,
div.code-toolbar > .toolbar.toolbar > .toolbar-item > button:hover,
div.code-toolbar > .toolbar.toolbar > .toolbar-item > button:focus {
  color: #e3eaf2;
  background: #005a8eda;
  text-decoration: none;
}

div.code-toolbar > .toolbar.toolbar > .toolbar-item > span,
div.code-toolbar > .toolbar.toolbar > .toolbar-item > span:hover,
div.code-toolbar > .toolbar.toolbar > .toolbar-item > span:focus {
  color: #e3eaf2;
  background: #3c526d;
}

/* overrides color-values for the Line Highlight plugin
 * http://prismjs.com/plugins/line-highlight/
 */
.line-highlight.line-highlight {
  background: #8da1b92f;
  background: linear-gradient(to right, #8da1b92f 70%, #8da1b925);
}

.line-highlight.line-highlight:before,
.line-highlight.line-highlight[data-end]:after {
  background-color: #3c526d;
  color: #e3eaf2;
  box-shadow: 0 1px #8da1b9;
}

pre[id].linkable-line-numbers.linkable-line-numbers
  span.line-numbers-rows
  > span:hover:before {
  background-color: #3c526d1f;
}

/* overrides color-values for the Line Numbers plugin
 * http://prismjs.com/plugins/line-numbers/
 */
.line-numbers.line-numbers .line-numbers-rows {
  border-right: 1px solid #8da1b97a;
  background: #d0dae77a;
}

.line-numbers .line-numbers-rows > span:before {
  color: #3c526dda;
}

/* overrides color-values for the Match Braces plugin
 * https://prismjs.com/plugins/match-braces/
 */
.rainbow-braces .token.token.punctuation.brace-level-1,
.rainbow-braces .token.token.punctuation.brace-level-5,
.rainbow-braces .token.token.punctuation.brace-level-9 {
  color: #755f00;
}

.rainbow-braces .token.token.punctuation.brace-level-2,
.rainbow-braces .token.token.punctuation.brace-level-6,
.rainbow-braces .token.token.punctuation.brace-level-10 {
  color: #af00af;
}

.rainbow-braces .token.token.punctuation.brace-level-3,
.rainbow-braces .token.token.punctuation.brace-level-7,
.rainbow-braces .token.token.punctuation.brace-level-11 {
  color: #005a8e;
}

.rainbow-braces .token.token.punctuation.brace-level-4,
.rainbow-braces .token.token.punctuation.brace-level-8,
.rainbow-braces .token.token.punctuation.brace-level-12 {
  color: #7c00aa;
}

/* overrides color-values for the Diff Highlight plugin
 * https://prismjs.com/plugins/diff-highlight/
 */
pre.diff-highlight > code .token.token.deleted:not(.prefix),
pre > code.diff-highlight .token.token.deleted:not(.prefix) {
  background-color: #c22f2e1f;
}

pre.diff-highlight > code .token.token.inserted:not(.prefix),
pre > code.diff-highlight .token.token.inserted:not(.prefix) {
  background-color: #116b001f;
}

/* overrides color-values for the Command Line plugin
 * https://prismjs.com/plugins/command-line/
 */
.command-line .command-line-prompt {
  border-right: 1px solid #8da1b97a;
}

.command-line .command-line-prompt > span:before {
  color: #6d3c6bda;
}

/* Blog Page */

.blog-con {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 8px;
}

.search_in {
  padding-left: 10px;
  font-size: 16px;
  height: 30px;
  width: 300px;
  background: #171d21;
  border-style: none;
  outline: none;
  color: #fff;
  border-radius: 8px;
}

/* input[type="text"]:focus {
  border: 1px solid #7cd959;
} */

.search_icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  cursor: pointer;
}

.wrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  align-items: stretch;
  margin: 0% 14% 0%;
}

.image-container {
  grid-row: span 2;
}

.image-container img {
  width: 100%;
  height: 80%;
  object-fit: cover;
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.box {
  display: grid;
  /* grid-template-rows: auto 1fr auto auto; */
  gap: 10px;
  color: #fff;
}

.box-h {
  color: #7cd959;
  font-size: 24px;
}

.box p {
  margin: 0;
  /* margin-bottom: 10px; */
  font-size: 14px;
  font-weight: 400;
}

.box-con {
  font-size: 18px;
  /* margin-bottom: 10px; */
  line-height: 24px;
}

.box-dt {
  font-size: 10px;
  margin-bottom: 10px;
}

.badge {
  background-color: #7cd959;
  color: white;
  padding: 2px 8px;
  text-align: center;
  border-radius: 5px;
}

.read-more {
  margin-left: -1%;
}

.recent_h {
  margin-left: 20%;
  margin-top: -2%;
  margin-bottom: -20px;
  font-size: 24px;
}

.blogs {
  display: grid;
  max-width: 1200px;
  margin-inline: auto;
  padding-inline: 24px;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 24px;
  padding: 48px;
  margin-bottom: 10%;
}

article {
  --img-scale: 1.001;
  --title-color: black;
  --link-icon-translate: -20px;
  --link-icon-opacity: 0;
  position: relative;
  border-radius: 16px;
  box-shadow: none;
  background: #171d21;
  transform-origin: center;
  transition: all 0.4s ease-in-out;
  overflow: hidden;
}

article h2 {
  color: #7cd959;
  transition: color 0.3s ease-out;
}

figure {
  margin: 0;
  padding: 0;
  aspect-ratio: 16 / 9;
  overflow: hidden;
}

article img {
  max-width: 100%;
  transform-origin: center;
  transform: scale(var(--img-scale));
  transition: transform 0.4s ease-in-out;
}

.article-body {
  padding: 24px;
}

.article-body p {
  font-size: 14px;
  font-weight: 400;
}

article:has(:hover, :focus) {
  --img-scale: 1.1;
  --link-icon-translate: 0;
  --link-icon-opacity: 1;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

button {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: #7cd959;
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}

button .icon {
  min-width: 24px;
  width: 24px;
  height: 24px;
  margin-left: 5px;
  transform: translateX(var(--link-icon-translate));
  opacity: var(--link-icon-opacity);
  transition: all 0.3s;
}

button::after {
  inset-block: 0;
  inset-inline: 0;
  cursor: pointer;
  content: "";
}

/*Single Blog Page*/
.see_all {
  display: flex;
  margin-left: 20px;
  margin-top: 10px;
  color: #7cd959;
  text-decoration: none;
  position: fixed;
  z-index: 99;
}

.single_blog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 300px;
}

.singleblog_image {
  width: 100%;
  height: auto;
  border: 1px solid #161d23;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  margin-bottom: 2%;
}

.singleblog_content {
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 10%;
  border: 1px solid #161d23;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  padding: 2%;
  text-align: center;
}

.singleblog_content::first-letter {
  -webkit-initial-letter: 2 1;
  initial-letter: 2 1;
  color: #7cd959;
  font-weight: 400;
  margin-right: 0.5em;
}

@media only screen and (max-width: 768px) {
  .search_in {
    width: 120px;
    height: 30px;
  }

  .search_icon {
    transform: scale(0.8);
    top: 2px;
  }

  .wrap {
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    padding: 10px;
    box-shadow: 8px 5px 15px #161d23;
    margin-bottom: 15%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .img-con {
    padding-bottom: 0;
  }

  .box-h {
    font-size: 16px;
  }

  .box-con {
    font-size: 12px;
  }

  .box-dt {
    font-size: 10px;
  }

  .recent_h {
    margin-left: 15%;
    margin-top: 10%;
  }

  .blogs {
    grid-template-columns: auto;
    margin-bottom: 30%;
  }

  .see_all {
    transform: scale(0.6);
  }

  .single_blog {
    margin: 30px 20px;
  }

  .singleBlog_h {
    font-size: 24px !important;
  }

  .singleblog_content {
    font-size: 14px;
    line-height: normal;
    overflow: auto;
    margin-bottom: 40%;
  }
}

/* iPad (portrait and landscape) */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .wrap {
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    padding: 10px;
    box-shadow: 8px 5px 15px #161d23;
    margin-bottom: 10%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .img-con {
    padding-bottom: 0;
  }

  .box-con {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }

  .recent_h {
    margin-left: 15%;
    margin-top: 10%;
  }
}

.resource_link {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-bottom: 4px solid transparent;
  border-radius: 4px;
  padding: 10px;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media (max-width: 767px) {
    font-size: 12px;
    margin-top: 2px;
  }
}

.resource_link:focus {
  outline: none !important;
}

.resource_link span {
  margin-top: -2px;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.resource_link span:focus {
  outline: none !important;
}

/* .resource_link:hover {
  border-radius:0;
  border-bottom:4px solid #7cd959;
  background-color: transparent;
} */

.resource_link::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -4px;
  width: 100%;
  height: 4px;
  border-radius: 4px;
  background-color: #7cd959;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.2s ease-in-out;
}

.resource_link:hover::after {
  visibility: visible;
  transform: scaleX(1);
}

.launch_app {
  border: 1px solid #7cd959;
  border-style: solid none none solid;
  background: #7cd959;
  border-radius: 6px;
  padding: 12px 18px;
  cursor: pointer;
  color: #000;
  font-weight: 700;
  text-transform: uppercase;
  transition: transform 0.3s ease;
  /* Added transition for transform property */
  display: inline-block;
}

.launch_app:hover {
  transform: scale(1.1);
  background: #59ef23;
  transition: all 250ms ease;
}

.resources {
  position: fixed;
  display: flex;
  flex-wrap: nowrap;
  left: 40%;
  transform: translateX(-30%);
  width: auto;
  background-color: #283642;
  z-index: 99;
  border-radius: 8px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.4);
  height: auto;
  top: 100px;

  @media (max-width: 1600px) {
    left: 50%;
    transform: translateX(-30%);
  }

  @media (max-width: 1440px) {
    left: 50%;
    transform: translateX(-30%);
  }

  @media (max-width: 1440px) {
    left: 50%;
    transform: translateX(-30%);
  }

  @media (max-width: 1280px) {
    left: 50%;
    transform: translateX(-30%);
  }

  @media (max-width: 1024px) {
    left: 50%;
    transform: translateX(-30%);
  }

  @media (max-width: 900px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 65px;
    width: 80%;
    padding: 10px;
  }
}

.resources:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 0;
  left: calc(100% - 70px);
  box-sizing: border-box;
  border: 1em solid #283642;
  border-color: transparent transparent #283642 #283642;
  transform-origin: 0 0;
  transform: rotate(135deg);
  box-shadow: -3px 3px 3px 0 rgba(40, 54, 66, 0.4);
}

@media (max-width: 900px) {
  .resources::after {
    display: none;
  }
}

.resource_link:hover .resources {
  display: block;
}

.sub-menu {
  padding: 30px;
  text-align: left;
  width: 100%;

  @media (max-width: 900px) {
    width: 100%;
    flex: 1;
    margin-bottom: 20px;
  }
}

.vertical-line {
  border-left: 1px solid #424242;
  height: auto;
  margin: 60px 0;

  @media (max-width: 900px) {
    display: none;
  }
}

.sub-menu a {
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  margin: 5px 0;
  color: #7cd959;
  text-align: left;
}

.sub-menu a p {
  color: #ffff;
  text-align: left;
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  text-transform: none;
  margin-top: 3px;
  letter-spacing: 1px;
}

.sub-menu a:hover {
  color: #ffff;
}

.sub-menu-h {
  width: 100%;
  padding-bottom: 15px;
  border-bottom: 1px solid #424242;
  line-height: 12px;
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  display: flex;
  align-items: center;
  letter-spacing: 2px;
}

.sub-menu-h img {
  width: 32px;
  height: 32px;
  object-fit: cover;
  margin-right: 10px;
}

.desktop-faqs {
  margin-bottom: 200px;
}

.section-title {
  font-size: 38px;
  margin: 60px 0;
  font-weight: 700;
  filter: drop-shadow(8px 5px 15px #161d23);
  letter-spacing: 1px;
}

.section-picture {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;

  .container-fluid {
    position: relative;
  }
}

.section-title-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 70px 0;

  @media (max-width: 1024px) {
    margin: 20px 0;
  }
}

.faq_img_tr {
  position: absolute;
  width: 110px;
  height: auto;
  top: -50px;
  right: 25px;
}

.faq_img_tl {
  position: absolute;
  width: 70px;
  height: auto;
  top: 150px;
  left: -20px;
}

.faq_img_bottom_left_faq {
  position: absolute;
  width: 150px;
  height: auto;
  top: 150px;
  right: 380px;
  z-index: -1;
}

.faq_img_bottom_right_faq {
  position: absolute;
  width: 100px;
  height: auto;
  top: 220px;
  right: 10px;
  z-index: -1;
}

.faq_img_bottom_middle_faq {
  position: absolute;
  width: 100px;
  height: auto;
  top: 150px;
  right: 40px;
  z-index: -1;
  transform: rotate(-45deg);
}

.desktop-faqs .MuiTypography-h5 {
  font-size: 16px;
  font-weight: 700;
  position: relative;
  color: #7cd959;

  @media (max-width: 767px) {
    font-size: 14px;
  }
}

.desktop-faqs .MuiTypography-h6 {
  font-size: 14px;
  font-weight: 700;
  color: #7cd959;
}

.desktop-faqs .MuiTypography-body2 {
  font-weight: 600;
  letter-spacing: 0.5px;
  opacity: 0.9;
  line-height: 1.9;
  margin-left: 52px;
  overflow: auto;
  /* scrollbar-width: none; */

  @media (max-width: 767px) {
    margin-left: 40px;
  }
}

.desktop-faqs .MuiTypography-body2 a,
.section-subtitle {
  color: #7cd959;
  font-weight: 700;
}

.section-subtitle {
  text-transform: none;
  font-size: 18px;
}

.section-subtitle a {
  font-style: italic;
  color: #7289da;
}

.faq_box {
  display: flex;
  flex-direction: column;
  background: #283642;
  border-radius: 12px;
  padding: 30px;
  width: 100%;
  max-width: 600px;
  height: 200px;

  @media (max-width: 767px) {
    padding: 15px;
    border-radius: 12px;
  }
}

.faq_box_h {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.faq_box_h img {
  margin-right: 15px;
  height: 30px;
  width: 30px;

  @media (max-width: 767px) {
    height: 24px;
    width: 24px;
    object-fit: cover;
  }
}

.faq_box_h div {
  flex: 1;
}

@media (max-width: 1200px) {
  footer .footer_wrappper {
    flex-direction: column;
  }

  footer p {
    margin: 20px 0;
    text-align: center;
  }

  footer .footer_wrappper > div {
    flex-wrap: wrap;
    justify-content: center;
  }

  footer a {
    margin: 0 12px;
  }

  footer .footer_wrappper .social {
    width: 100%;
    justify-content: center;
    margin-top: 35px;
    padding-top: 35px;
    border-top: 1px solid #444444;
  }
}

@media (max-width: 1024px) {
  .section-title {
    font-size: 30px;
    margin: 50px 0;
  }

  .about-desktop .MuiCard-root {
    margin: 24px 0;
  }

  .desktop-faqs {
    margin-bottom: 70px;
  }

  .faq_box {
    height: auto;
  }

  .faq_img_tl,
  .faq_img_bottom_left_faq,
  .faq_img_bottom_middle_faq,
  .faq_img_bottom_right_faq,
  .faq_img_tr {
    display: none;
  }
}

@media (max-width: 900px) {
  .new-hero-section {
    padding-top: 60px;
  }

  .desktop-title {
    font-size: 30px !important;
  }

  .desktop-title + p,
  .section-subtitle {
    font-size: 12px;
    line-height: 1.9;
  }

  .section-title {
    font-size: 24px;
    margin: 25px 0;
    text-align: center;
  }

  .section-subtitle {
    font-size: 12px;
    margin: 25px 0;
    text-align: center;
  }

  .about-desktop .MuiCard-root {
    margin: 20px 0;
  }

  .desktop-faqs {
    margin-bottom: 50px;
  }

  .desktop-faqs .MuiGrid2-root.MuiGrid2-container > div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .site_header {
    padding: 25px 5px;
  }

  .faq_img_tl,
  .faq_img_bottom_left_faq,
  .faq_img_bottom_middle_faq,
  .faq_img_bottom_right_faq,
  .faq_img_tr {
    display: none;
  }
}

@media (max-width: 767px) {
  .agencies {
    .container-fluid {
      padding: 0;
      margin-top: 10%;
    }

    .launch_app {
      display: inline-block;
    }

    .hero_title {
      display: inline-block;
      font-size: 30px;

      span {
        font-size: 30px;
      }
    }
  }

  .site_header {
    padding: 15px 10px 15px 0;
  }

  .site_header .site_logo img {
    max-width: 150px;
  }

  .launch_app {
    display: none;
  }

  .site_header .header_wrapper div a {
    font-size: 12px;
    margin-left: 0 !important;
  }

  .site_header .container-fluid {
    padding-right: 0;
  }

  footer .footer_wrappper > div > a {
    width: 100%;
    text-align: center;
    margin: 10px 0;
  }

  .sub-menu {
    width: 100%;
    padding: 15px;
  }

  .sub-menu a {
    font-size: 14px;
  }

  .sub-menu a p {
    font-size: 10px;
  }
}

.new-hero-section {
  min-height: 50vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 767px) {
    padding: 15px;
    min-height: 70vh;
  }
}

.new-hero-section .support {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 10px;
  @media (max-width: 767px) {
    display: flex;
    gap: 0px;
  }
  .techstars {
    background: #fff;
    padding: 20px;
  }
  .techstars,
  .nvidia {
    border-radius: 12px;
    @media (max-width: 767px) {
      transform: scale(0.8);
    }
  }
}

.new-hero-section .support {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 10px;
  @media (max-width: 767px) {
    display: flex;
    gap: 0px;
  }
  .techstars {
    background: #fff;
    padding: 20px;
  }
  .techstars,
  .nvidia {
    border-radius: 12px;
    @media (max-width: 767px) {
      transform: scale(0.8);
    }
  }
}

.new-hero-section .background_image,
.agencies .background_image {
  position: absolute;
  top: -15%;
  width: 100%;
  object-fit: cover;
  object-position: bottom center;

  @media (max-width: 2000px) {
    top: -5%;
  }

  @media (max-width: 1440px) {
    top: -10%;
  }

  @media (max-width: 1440px) {
    top: -10%;
  }

  @media (max-width: 1280px) {
    top: -4%;
  }

  @media (max-width: 1024px) {
    width: 100%;
    height: 100%;
    top: -20%;
  }
}

.brand_backimg {
  transform: rotateX(180deg);
  height: 120%;
}

.new-hero-section .hero_girl {
  position: absolute;
  left: 30px;
  max-height: calc(100vh - 200px);
  max-width: calc(50vw - 150px);
  bottom: -70%;
  z-index: 0;

  @media (max-width: 767px) {
    max-width: calc(50vw - 20px);
    max-height: calc(100vh - 100px);
    left: 2px;
  }
}

.new-hero-section .hero_boy {
  position: absolute;
  right: 20px;
  max-height: calc(100vh - 200px);
  max-width: calc(50vw - 150px);
  bottom: -70%;
  z-index: 0;

  @media (max-width: 767px) {
    max-width: calc(50vw - 20px);
    max-height: calc(100vh - 100px);
    right: 10px;
  }
}

.new-hero-wrapper {
  position: relative;
  z-index: 1;
  text-align: center;
  transform: translateY(+100px);

  @media (max-width: 1199px) {
    transform: translateY(+50px);
  }
}

.new-hero-wrapper .hero_title {
  font-size: 54px;
  text-transform: uppercase;
  letter-spacing: 1px;

  @media (max-width: 1600px) {
    font-size: 48px;
  }

  @media (max-width: 1367px) {
    font-size: 40px;
  }

  @media (max-width: 1199px) {
    font-size: 22px;
  }
}

.new-hero-wrapper .hero_title span {
  color: #7cd959;
}

@media (max-width: 767px) {
  .hero_title {
    display: flex;
    flex-direction: column;
    line-height: 1.5;
    font-size: 30px;
    /* margin-bottom: 30%;    */
  }

  .hero_title span {
    font-size: 30px;
    padding: 10px 0;
  }
}

.new-hero-wrapper .benefits {
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: center;
  padding-left: 0;
  margin-bottom: 50px;
  margin-top: 10px;

  @media (max-width: 1600px) {
    margin-bottom: 35px;
  }

  @media (max-width: 767px) {
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
}

.new-hero-wrapper .benefits li {
  margin: 0 12px;
  /* flex: 1 1 auto; */

  img {
    margin-left: 5px;
  }

  @media (max-width: 1600px) {
    font-size: 14px;
    margin: 0 10px;
  }

  @media (max-width: 1367px) {
    font-size: 13px;
    margin: 0 6px;
  }

  @media (max-width: 767px) {
    max-width: 100%;
    margin: 5px 0;
  }
}

@media (max-width: 767px) {
  .benefits li:first-child {
    margin-left: 25%;
  }

  .benefits li:nth-child(2) {
    margin-right: 25%;
  }
}

.new-hero-wrapper a img {
  max-width: 300px;
  -webkit-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;

  @media (max-width: 1600px) {
    max-width: 250px;
  }

  @media (max-width: 1367px) {
    max-width: 200px;
  }

  @media (max-width: 767px) {
    margin-bottom: 2%;
    max-width: 200px;
  }
}

.new-hero-wrapper a img:hover {
  transform: scale(0.95);
}

.new-hero-wrapper .snap_logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 767px) {
  .new-hero-wrapper .snap_logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 0%;
  }
}

.leave-top_margin {
  margin-top: 85px;
}

@keyframes pulseAnim {
  0% {
    opacity: 0;
    transform: translateY(-20px) translateX(-50%);
  }

  50% {
    opacity: 0.5;
    transform: translateY(-10px) translateX(-50%);
  }

  100% {
    opacity: 1;
    transform: translateY(0px) translateX(-50%);
  }
}

.scroll-down {
  position: absolute;
  left: 50%;
  top: calc(60vh - 50px);
  transform: translateX(-50%);
  cursor: pointer;

  @media (max-width: 767px) {
    display: none;
  }

  .animate {
    color: #7cd959;
    font-size: 12px;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 3px;

    @media (max-width: 767px) {
      font-size: 10px;
    }
  }

  &::after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -25px;
    width: 30px;
    height: 30px;
    background: url(/src/imgs/arrow-down.png) no-repeat center;
    background-size: 16px;
    opacity: 0;
    animation: pulseAnim 2s linear infinite;
    animation-delay: 0.25s;

    @media (max-width: 767px) {
      background-size: 12px;
    }
  }

  &::before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -34px;
    width: 30px;
    height: 30px;
    background: url(/src/imgs/arrow-down.png) no-repeat center;
    background-size: 16px;
    opacity: 0;
    animation: pulseAnim 2s linear infinite;
    animation-delay: 0.5s;

    @media (max-width: 767px) {
      background-size: 12px;
    }
  }
}

.section_features_main {
  padding-bottom: 100px;
  /* overflow: hidden; */

  @media (max-width: 1024px) {
    padding-bottom: 50px;
  }

  .container-fluid {
    position: relative;
  }

  .coin-img {
    position: absolute;
    left: -100px;
    top: 50px;
    max-width: 140px;

    @media (max-width: 1440px) {
      left: -50px;
      top: 0;
    }

    @media (max-width: 1199px) {
      max-width: 100px;
    }

    @media (max-width: 767px) {
      max-width: 75px;
      top: -50px;
      left: 0;
    }
  }

  .thunder-img {
    position: absolute;
    left: 40%;
    /* transform: translateX(-50%); */
    transform: rotate(-22deg);
    top: 100%;
    z-index: 1;
    /* max-width: 120px; */
    max-width: 70px;

    @media (max-width: 1199px) {
      max-width: 50px;
    }

    @media (max-width: 1024px) {
      max-width: 40px;
      top: 90%;
    }

    @media (max-width: 767px) {
      max-width: 30px;
      top: 100%;
    }
  }

  .mask-img {
    position: absolute;
    left: calc(53% + 100px);
    top: 10px;
    z-index: -100;
    max-width: 140px;

    @media (max-width: 1199px) {
      max-width: 100px;
    }

    @media (max-width: 1199px) {
      left: calc(50% + 75px);
      top: 15px;
    }

    @media (max-width: 767px) {
      max-width: 70px;
      top: 0px;
      left: auto;
      right: 40px;
    }
  }

  .MuiGrid2-root.MuiGrid2-container {
    width: 100%;
    margin: auto;
  }

  .MuiGrid2-root.MuiGrid2-container > div {
    @media (max-width: 1024px) {
      width: 100%;
      padding: 15px;
    }
  }

  .feature-title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
    padding-left: 20px;

    @media (max-width: 1199px) {
      padding-left: 10px;
      font-size: 18px;
    }

    span {
      color: #7cd959;
    }
  }

  .feature-decs {
    font-size: 14px;
    color: #d9d9d9;
    display: block;
    line-height: 1.4;
    padding-left: 20px;
    margin-top: 15px;

    @media (max-width: 1199px) {
      padding-left: 10px;
    }
  }

  .feature_video-wrapper {
    position: relative;

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      max-width: 60px;
    }
  }

  .feature-video {
    width: 100%;
    border-radius: 24px;
    position: relative;

    &.green-shadow {
      box-shadow: 6px 6px 0 #7cd959;
    }

    &.orange-shadow {
      box-shadow: 6px 6px 0 #f28e46;
    }

    &.blue-shadow {
      box-shadow: 6px 6px 0 #83b1dc;
    }
  }
}

/* Artist page update */
.separator {
  border-top: 2px solid #283642;
  margin: 50px 0;
}

.info_p {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #283642;
  padding: 12px 0;
  margin: 16px 0;
  margin-top: 30px;
  border-radius: 16px;
  gap: 10px;
  width: 80%;

  p {
    width: 90%;
    margin: 10px 0;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
  }
}

.green {
  padding: 0.5px 0;
  width: 65%;
  background-color: #7cd959 !important;

  p {
    color: #fff;

    a {
      color: #fff !important;
    }
  }
}

.img_container {
  width: 450px;
  height: 200px;
  margin-top: 5%;
  margin-left: 5%;
  padding: 8px 0;
  gap: 10px;

  p {
    font-size: 12px;
  }
}

.example_img {
  cursor: pointer;
}

.faqimg-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.faqimg-container .after {
  position: absolute;
  top: 0;
  left: 0;
  /* width: 100%;
  height: 100%; */
  display: none;
}

.faqimg-container:hover .after {
  display: block;
  background: rgba(0, 0, 0, 0.6);
}

.icon {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
  margin-left: 10%;
}

.icon:hover {
  color: #7cd959;
}

/* Modal styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
    max-height: 80vh;
    width: auto;
    height: auto;
    display: block;
    margin: 0 auto;
  }
}

.close_m {
  position: absolute;
  top: 0;
  right: 35px;
  color: #7cd959;
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;
}

.close_m:hover,
.close_m:focus {
  color: #7cd959;
  text-decoration: none;
  cursor: pointer;
}

@media (max-width: 900px) {
  .adding_shape_video {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .img_container {
    width: calc(100% - 10%);
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
}

.marquee-container {
  display: flex;
  flex-direction: column;
  background-color: #283642;
  border-radius: 12px;
  margin: 2% 3% 4% 15%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 0px 8px;
  align-items: center;
}

.desktop-grid {
  transform: scale(0.9);

  @media (max-width: 2000px) {
    margin-top: 100px !important;
    transform: scale(1) !important;
  }

  /* @media (max-width: 1440px) {
    margin-top: 80px !important;
  } */
}

@media only screen and (min-width: 768px) {
  /* .desktop-grid {
    transform: scale(0.9);
  } */

  .desktop-h {
    display: block !important;
    margin-top: 2%;
    margin-bottom: -2%;
    font-size: 12px !important;
  }

  .mobile_brands h6 {
    display: none;
  }
}

.brands {
  display: flex;
  justify-content: center;
  align-items: center;
}

.brand_logo {
  flex: 0 0 auto;
  max-width: 100%;
  max-height: 100%;
  width: 100px;
  height: 100px;
  margin: 0 10px;

  @media only screen and (min-width: 768px) {
    width: auto;
    height: 80px;
  }
}

@media (max-width: 1024px) {
  .mobile_brands {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 767px) {
      margin-top: 15%;
    }
  }
}

@media (max-width: 767px) {
  .mobile_brands h6 {
    font-size: 12px !important;
  }

  .desktop-h {
    display: none;
  }
}

/* About page */

.about_main {
  display: flex;
  justify-content: center;
  padding: 20px;
  background: #171d21;
  color: #fff;
}

@media (max-width: 900px) {
  .about_main,
  .about_right,
  .feature_video-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.about_left,
.about_right {
  padding: 20px;
}

.about_left {
  flex: 1 1 33.33%;
  padding-left: 10%;
  /* background-image:url(/src/imgs/about_left.webp); */
}

.about_right {
  flex: 2 1 66.67%;
  padding-right: 10%;
}

.about_right_h {
  margin-top: 10px;
  font-size: 24px;
  width: 80%;

  @media (max-width: 900px) {
    width: 100%;
    font-size: 18px;
  }
}

.about_right_p {
  text-align: left;
  margin-top: 50px;
  font-size: 16px;
  width: 80%;

  @media (max-width: 900px) {
    width: 100%;
  }
}

.feature_video-wrapper {
  /* margin-top: 50px; */
  display: flex;
  justify-content: left;
}

.feature-video {
  width: 50%;
  max-width: 600px;
  height: auto;
  border-radius: 24px;

  @media (max-width: 900px) {
    width: 100%;
  }
}

.green-shadow {
  box-shadow: 6px 6px 0 #7cd959;
}

.cta_btn_about {
  border: 1px solid #7cd959;
  border-style: solid none none solid;
  background: #7cd959;
  border-radius: 6px;
  padding: 12px 18px;
  cursor: pointer;
  text-transform: uppercase;
  display: inline-block;
  margin-top: 50px;
  margin-right: 20px;
  -webkit-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;
  text-decoration: none;

  @media (max-width: 900px) {
    padding: 6px 9px;
  }
}

.cta_btn_about:hover {
  transform: scale(0.95);
}

.cta_btn_about span {
  color: #000;
  font-weight: bold;
}

.twitchextnsn {
  color: #6441a5;
  text-decoration: none;
}

.twitchextnsn_green {
  color: #7cd959;
  text-decoration: none;
}

.giveaway-wrapper {
  margin: 60px 0px;
  padding: 0 20px;
  position: relative;
  margin-top: 80px;

  .giveaway-hero-section {
    .giveaway-text {
      font-size: 4vw;
      font-weight: 800;
      text-transform: uppercase;
      line-height: 0.92;
      margin-left: 20%;

      @media (max-width: 1750px) {
        font-size: 3vw;
      }

      @media (max-width: 1200px) {
        text-align: center;
        margin-left: 0;
        line-height: 1.1;
        font-size: 30px;
      }

      p {
        background: linear-gradient(to right, #7cd959, #cfff22);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        color: transparent;
        font-size: 5vw;
        font-weight: 900;
        font-style: italic;
        margin-left: -10px;

        /* span {
          letter-spacing: -10px;
        } */

        @media (max-width: 1750px) {
          font-size: 5vw;
        }

        @media (max-width: 1200px) {
          margin-left: 0;
          font-size: 60px;

          span {
            letter-spacing: -3px;
          }
        }

        @media (max-width: 480px) {
          font-size: 50px;
        }
      }

      .bolt_bg_a {
        position: absolute;
        left: 7%;
        top: 5%;
        transform: translate(-50%, 70%);
        z-index: 4;
      }
    }

    .giveaway-tagline {
      padding-left: 15px;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.6;
      margin-left: 20%;

      @media (max-width: 1750px) {
        font-size: 18px;
        padding-left: 5px;
      }

      @media (max-width: 1200px) {
        font-size: 16px;
        text-align: center;
      }

      @media (max-width: 1200px) {
        padding-left: 0;
      }

      span {
        color: #7cd959;
        font-style: italic;
        font-weight: 700;
      }
    }

    .participate-btn {
      background: #7cd959;
      border: 1px solid #7cd959;
      border-radius: 42px;
      padding: 5px 30px !important;
      text-align: center;
      color: #fff !important;
      font-size: 24px !important;
      font-weight: 700;
      font-style: italic;
      text-decoration: none !important;
      cursor: pointer;
      transition: transform 0.3s ease;
      margin-left: 20%;
    }

    .participate-btn:hover {
      transform: scale(1.1);
      transition: all 250ms ease;
    }

    .giveaway-banner {
      img {
        width: 70%;
        margin-left: 10%;
        margin-top: 2%;
      }
    }
  }

  .steps-tagline {
    color: #fff;
    font-weight: 500;
    font-size: 20px;

    @media (max-width: 1750px) {
      font-size: 16px;
    }

    span {
      font-size: 35px;
      color: #7cd959;
      font-weight: 700;
      font-style: italic;
      line-height: 1;

      @media (max-width: 1750px) {
        font-size: 28px;
      }

      &.big_text {
        font-size: 50px;

        @media (max-width: 1750px) {
          font-size: 38px;
        }
      }
    }
  }

  .giveaway-steps-wrapper {
    position: relative;
    z-index: 9;
    margin: 0 40px;
    transform: scale(0.8);
    transform-origin: top center;

    .gift_bg {
      position: absolute;
      left: 0;
      top: 0;
      transform: translate(50%, -50%);
      z-index: -1;

      @media (max-width: 1200px) {
        display: none;
      }
    }

    .bolt_bg {
      position: absolute;
      right: 80px;
      bottom: 10px;
      width: 170px;
      transform: translate(-50%, 60%);
      z-index: -1;
    }
  }

  .giveaway-steps {
    border: 1px solid rgba(#fff);
    backdrop-filter: blur(12px);
    border-radius: 25px;
    box-shadow: 0px 0px 28px rgba(0, 0, 0, 0.45);
    margin-bottom: 30px;

    .giveaway-steps_grids {
      justify-content: center;

      & > div {
        @media (max-width: 1600px) {
          max-width: 33.33%;
          flex-basis: 33.33%;
        }

        @media (max-width: 1200px) {
          max-width: 50%;
          flex-basis: 50%;

          &:nth-child(4) {
            .vertical-line {
              display: block;
            }
          }
        }

        @media (max-width: 991px) {
          max-width: 100%;
          flex-basis: 100%;

          .vertical-line {
            display: none !important;
          }
        }
      }
    }

    .giveaway-step {
      padding: 30px 0px 30px 30px;
      position: relative;

      @media (max-width: 1750px) {
        padding: 20px 0 20px 20px;
      }

      .step-img {
        height: 35px;
        margin-bottom: 18px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
      }

      .vertical-line {
        position: absolute;
        top: 25px;
        left: 10px;
        width: 1px;
        height: calc(100% - 100px);
        background-color: rgba(#fff);
        border-radius: 50%;
        z-index: 9;
        transform: rotate(14deg);
        box-shadow: 0px 0px 7px rgba(#000000);

        @media (max-width: 1750px) {
          height: calc(100% - 90px);
          top: 18px;
        }
      }

      h3 {
        font-size: 22px !important;
        color: #7cd959;
        font-weight: 700;
        padding-left: 40px;

        img {
          /* margin-left: 0px; */
          max-width: 24px;

          @media (max-width: 1850px) {
            max-width: 25px;
          }

          @media (max-width: 1750px) {
            max-width: 20px;
          }
        }

        @media (max-width: 1850px) {
          font-size: 24px;
        }

        @media (max-width: 1750px) {
          font-size: 24px;
          padding-left: 34px;
        }

        @media (max-width: 1600px) {
          font-size: 24px;
        }

        @media (max-width: 991px) {
          padding-left: 0;
        }
      }

      p {
        margin: 24px 0;
        padding-left: 28px;

        @media (max-width: 1750px) {
          font-size: 16px;
          padding-left: 20px;
        }

        @media (max-width: 991px) {
          padding-left: 0;
          margin: 15px 0;
        }

        span {
          color: #7cd959;
          font-weight: 600;
          font-style: italic;
          border-bottom: 1px solid #7cd959;
          margin-right: 2px;
          cursor: pointer;
        }
      }

      .giveaway-stats {
        color: #7cd959;
        text-align: center;
        max-width: calc(100% - 30px);

        @media (max-width: 1750px) {
          max-width: calc(100% - 20px);
        }
      }
    }
  }
}

.giveaway-btn {
  -webkit-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;
  cursor: pointer;
  position: fixed;
  bottom: 2px;
  right: 20px;
  z-index: 99;
}

.giveaway-btn:hover {
  transform: scale(0.95);
}

.background-container {
  position: relative;
  width: 350px;
  height: 260px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.giveaway-btn-text {
  font-size: 56px;
  font-weight: bold;
  font-style: italic;
  text-align: center;
  width: 310px;
  transform: rotate(-4deg);
  z-index: 5;
  background: linear-gradient(to right, #7cd959, #cfff22);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.background-one {
  position: absolute;
  width: 300px;
  height: 159px;
  background-color: #7cd959;
  z-index: 1;
  border-radius: 15px;
  transform: rotate(5deg);
  margin-top: 60px;
}

.background-two {
  position: absolute;
  width: 300px;
  height: 170px;
  background-color: #283642;
  z-index: 2;
  border-radius: 15px;
  margin-top: 50px;
}

.dark-keypad {
  position: absolute;
  width: 350px;
  right: 8%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
}

.gift-icon {
  position: absolute;
  width: 35px;
  z-index: 3;
}

.gift-icon-1 {
  top: 25%;
  right: 10%;
  transform: rotate(70deg);
}

.gift-icon-2 {
  width: 50px;
  bottom: 7%;
  left: 55%;
}

.mobile-giveaway {
  display: none;
}

@media screen and (max-width: 1024px) {
  .giveaway-btn {
    transform: scale(0.5);
    transform-origin: top center;
    z-index: 3;
    left: 40%;
  }

  .giveaway-btn:hover {
    transform: scale(0.5);
  }

  .mobile-giveaway {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;

    .mobile-giveaway-hero {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;

      .giveaway-btn-text {
        width: 90%;
        font-size: 48px;
        margin-top: 15%;
      }

      .dark-keypad-mobile {
        width: 90%;
        position: relative;
        margin-top: -70px;
        z-index: 1;
      }

      .gift-icon-1 {
        position: absolute;
        top: 33%;
        transform: rotate(0deg) !important;
        left: 16%;
        width: 50px;
      }

      .gift-icon-2 {
        position: absolute;
        top: 33%;
        left: 65% !important;
        width: 70px !important;
      }
    }

    .mobile-giveaway-tagline {
      padding: 0 25px;
      font-weight: 700;
      font-style: italic;
      text-align: center;
      z-index: 5;
    }

    .mobile-bolt {
      position: absolute;
      bottom: 20%;
      left: -2%;
      z-index: 0;
      width: 80px;
    }

    .mobile-gift-1 {
      position: absolute;
      width: 60px;
      top: 76%;
      right: 2%;
      transform: rotate(60deg);
    }

    .mobile-gift-2 {
      position: absolute;
      width: 100px;
      bottom: 5%;
      left: 20%;
      transform: rotate(-60deg);
    }

    .mobile-giveaway-steps {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 80%;
      position: relative;
      z-index: 9;
      margin: 10px 40px;
      border: 1px solid rgba(#fff);
      backdrop-filter: blur(12px);
      border-radius: 25px;
      box-shadow: 0px 0px 28px rgba(0, 0, 0, 0.45);
      padding: 10px 0;

      .mobile-giveaway-banner {
        width: 80%;
      }

      .participate-btn {
        padding: 4px 34px !important;
        font-size: 20px !important;
        margin-top: 20px;
        margin-bottom: 20px;
        background: #7cd959;
        border: 1px solid #7cd959;
        border-radius: 42px;
        text-align: center;
        color: #fff !important;
        font-weight: 700;
        font-style: italic;
        text-decoration: none !important;
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 768px) {
  .mc-modal,
  .mc-banner {
    position: fixed !important;
    top: 18% !important;
    z-index: 9999;
  }
}

.campaign_card {
  color: white !important;
  height: 100%;
  border: 1px solid;
  background-color: #171d21 !important;
  border-radius: 20px !important;
}

@media only screen and (min-width: 768px) {
  .campaign_card {
    transform: scale(0.85);
  }
}

.agency_section {
  position: relative;
  /* margin-left: 100px; */
  margin-left: 2%;
  padding: 0 20px;
  margin-top: -60px;
  margin-bottom: 140px;

  @media (max-width: 768px) {
    margin: 50px 0;
  }

  @media (max-width: 1440px) {
    margin-top: 10px;
  }

  @media (max-width: 2000px) {
    margin-top: 50px;
  }
}

.agencies {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 28px;
}

.dev_layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2%;
  margin-bottom: 5%;
  gap: 20px;
}

.row {
  display: flex;
  gap: 20px;
  width: 50vw;
  justify-content: space-between;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 50vw;
}

.custom-form {
  flex: 1;
}

.api_form {
  width: 50vw;
}
.custom-form {
  .custom-label {
    font-weight: 700;
    color: #7cd959;
  }

  .custom-label.Mui-focused {
    color: #fff;
  }

  .custom-input .MuiOutlinedInput-notchedOutline {
    border: 2px solid #7cd959;
    border-radius: 12px;
  }

  .custom-input:hover .MuiOutlinedInput-notchedOutline {
    border-color: #fff;
    border-radius: 12px;
  }

  .custom-input.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #fff !important;
  }

  .custom-input .MuiOutlinedInput-input {
    color: #fff;
  }
}

.custom-textfield {
  width: 50vw;
  margin-bottom: 1rem;
}

.custom-textfield .MuiOutlinedInput-root {
  border-radius: 12px;
  border-color: #7cd959;
}

.custom-textfield .MuiOutlinedInput-notchedOutline {
  border-radius: 12px;
  border-color: #7cd959;
}

.custom-textfield:hover .MuiOutlinedInput-notchedOutline {
  border-color: #fff !important;
}

.custom-textfield .MuiInputLabel-root {
  color: #7cd959;
  font-weight: 700;
}

.custom-textfield .MuiInputLabel-root.Mui-focused {
  color: #fff !important;
  border-color: #fff !important;
}

.custom-textfield .MuiOutlinedInput-input {
  color: #fff !important;
}

.launch_app.disabled {
  border: 1px solid #7cd959;
  background: transparent;
  border-radius: 12px;
  color: #fff;
}
/* Responsive Design */
@media (max-width: 768px) {
  .row,
  .column {
    flex-direction: column;
    /* width: 90%; */
  }

  .dev_layout {
    padding: 5%;
  }
}
/* API-Doc */
.code-block {
  background: #283642;
  color: #fff;
  padding: 1rem;
  border-radius: 12px;
  margin-top: 1rem;
  overflow-x: auto;
  max-width: 600px;
}
.copy-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #0000;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 10px;
}

.block-h{
  display: flex;
  align-items: left;
  margin-top: 10px;
}

.string{
  background: #455363;
  border-radius: 4px;
  font-size: 12px;
  padding: 2px;
}

.next-btn{
  display: block;
  color: #fff;
  font-weight:bold;
  padding: 8px 18px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #7cd959;
  border: none;
  border-radius: 18px;
  cursor: pointer;
  z-index: 1000;
}
